import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-policy-view">
      <h1>Privacy Policy for Helplink.AI, a Solvpath LLC Company</h1>
      <p>
        <strong>Effective Date:</strong> 10/14/2023
      </p>

      <h2>1. Introduction</h2>
      <p>
        Helplink.AI, a Solvpath LLC Company, is committed to protecting your
        privacy. This Privacy Policy outlines how we collect, use, and safeguard
        your personal information. By accessing and using our services, you
        consent to the practices described in this policy.
      </p>

      <h2>2. Information We Collect</h2>
      <p>
        We may collect various types of information, including but not limited
        to:
      </p>
      <ul>
        <li>
          <strong>Personal Information:</strong> We may collect information that
          personally identifies you, such as your name, email address, postal
          address, phone number, and other contact details.
        </li>
        <li>
          <strong>Usage Information:</strong> We may collect information about
          how you use our website and services, including your IP address,
          browser type, operating system, and the pages you visit.
        </li>
        <li>
          <strong>Cookies:</strong> We may use cookies and similar tracking
          technologies to collect information about your interactions with our
          website. You can manage your cookie preferences in your browser
          settings.
        </li>
      </ul>

      <h2>3. How We Use Your Information</h2>
      <p>We may use your information for the following purposes:</p>
      <ul>
        <li>To provide and improve our services.</li>
        <li>To respond to your inquiries and requests.</li>
        <li>
          To send you updates, newsletters, and marketing communications (if you
          have opted in).
        </li>
        <li>To monitor and analyze website usage and trends.</li>
        <li>To comply with legal obligations.</li>
      </ul>

      <h2>4. How We Share Your Information</h2>
      <p>We may share your information with:</p>
      <ul>
        <li>
          <strong>Service Providers:</strong> We may share your information with
          third-party service providers who help us operate, maintain, and
          enhance our services.
        </li>
        <li>
          <strong>Legal Compliance:</strong> We may disclose your information to
          comply with legal requirements, protect our rights, or respond to
          lawful requests by public authorities.
        </li>
        <li>
          <strong>Business Transfers:</strong> In the event of a merger, sale,
          or transfer of all or a portion of our business, your information may
          be part of the assets transferred.
        </li>
      </ul>

      <h2>5. Your Choices</h2>
      <p>
        You have choices regarding the personal information we collect and how
        it is used:
      </p>
      <ul>
        <li>You can update your personal information by contacting us.</li>
        <li>You can opt out of marketing communications at any time.</li>
        <li>
          You can manage your cookie preferences in your browser settings.
        </li>
      </ul>

      <h2>6. Data Security</h2>
      <p>
        We employ reasonable security measures to protect your information from
        unauthorized access, disclosure, alteration, or destruction.
      </p>

      <h2>7. Third-Party Links</h2>
      <p>
        Our website may contain links to third-party websites or services. We
        are not responsible for the privacy practices or content of these third
        parties. Please review the privacy policies of these websites before
        providing any personal information.
      </p>

      <h2>8. Children's Privacy</h2>
      <p>
        Our services are not directed at individuals under the age of 13. We do
        not knowingly collect or maintain information from anyone under 13 years
        of age.
      </p>

      <h2>9. Updates to this Privacy Policy</h2>
      <p>
        We may update this Privacy Policy to reflect changes to our information
        practices. We will post the updated policy on our website, and the
        revised version will be effective as of the updated date.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions, concerns, or requests related to this Privacy
        Policy, please contact us at:
      </p>

      <p style={{ marginTop: "3rem" }}>
        <strong>Helplink.AI- Solvpath LLC Company</strong>
      </p>
      <p>244 Pettit Ave</p>
      <p>Bellmore, NY 11710</p>
      <p>Email: support@solvpath.com</p>
    </div>
  );
};

export default PrivacyPolicy;
