import { useEffect, useState } from "react";
import "./../HelpLink.scss";
import logo from "./../logo.svg";
import { Cancel, Menu } from "iconoir-react";
import { useLocation, useNavigate } from "react-router-dom";

const Header = ({
  homeRef,
  whoWeAreRef,
  partnersRef,
  isMobile,
}: {
  homeRef: React.RefObject<HTMLElement>;
  whoWeAreRef: React.RefObject<HTMLElement>;
  partnersRef: React.RefObject<HTMLElement>;
  isMobile: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const scrollToHome = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    if (homeRef.current) {
      setIsMenuOpen(false);
      homeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToWhoWeAre = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    if (whoWeAreRef.current) {
      setIsMenuOpen(false);
      const offset = isMobile ? 0 : -20;
      const targetPosition =
        whoWeAreRef.current.getBoundingClientRect().top +
        window.scrollY -
        offset;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  const scrollToPartners = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    if (partnersRef.current) {
      setIsMenuOpen(false);
      const offset = 40;
      const targetPosition =
        partnersRef.current.getBoundingClientRect().top +
        window.scrollY -
        offset;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={`header-view ${isScrolled ? "header-fixed" : ""}`}>
      <div className="header">
        <img src={logo} alt="logo" onClick={() => navigate("/")} />
        {isMobile ? (
          <>
            <div className="menu-btn">
              {!isMenuOpen ? (
                <Menu onClick={() => setIsMenuOpen(true)} />
              ) : (
                <Cancel onClick={() => setIsMenuOpen(false)} />
              )}
            </div>
            {isMenuOpen && (
              <div className="menu">
                <span onClick={scrollToHome}>Home</span>
                <span onClick={scrollToWhoWeAre}>Who We Are</span>
                <span onClick={scrollToPartners}>Our Partners</span>
              </div>
            )}
          </>
        ) : (
          <div className="header-links">
            <span onClick={scrollToHome}>Home</span>
            <span onClick={scrollToWhoWeAre}>Who We Are</span>
            <span onClick={scrollToPartners}>Our Partners</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
