import "./../HelpLink.scss";
import logo from "./../logo_alt.svg";
import footerInfo from "./../assets/footer-info.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Footer = ({
  homeRef,
  whoWeAreRef,
  partnersRef,
  isMobile,
}: {
  homeRef: React.RefObject<HTMLElement>;
  whoWeAreRef: React.RefObject<HTMLElement>;
  partnersRef: React.RefObject<HTMLElement>;
  isMobile: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToHome = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    if (homeRef.current) {
      homeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToWhoWeAre = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    if (whoWeAreRef.current) {
      const offset = isMobile ? 0 : -40;
      const targetPosition =
        whoWeAreRef.current.getBoundingClientRect().top +
        window.scrollY -
        offset;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  const scrollToPartners = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    if (partnersRef.current) {
      const offset = 40;
      const targetPosition =
        partnersRef.current.getBoundingClientRect().top +
        window.scrollY -
        offset;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="footer-view">
      <div className="footer">
        <div className="website-info-links">
          <img src={logo} alt="logo" onClick={() => navigate("/")} />
          <div className="quick-links">
            <h6>Quick Links</h6>
            <div className="links">
              <span onClick={scrollToHome}>Home</span>
              <span onClick={scrollToWhoWeAre}>Who We Are</span>
              <span onClick={scrollToPartners}>Our Partners</span>
            </div>
          </div>
        </div>
        <div className="product-info-links">
          <div className="socials">
            {/* <button className="social-btn">
              <Facebook color="white" fill="white" />
            </button>
            <button className="social-btn">
              <Twitter color="white" fill="white" />
            </button>
            <button className="social-btn">
              <LinkedIn color="white" strokeWidth={2} />
            </button> */}
          </div>
          <div className="solv-logo">
            <img src={footerInfo} alt="product-info" />
          </div>
          <span className="rights">
            <Link to="/privacy-policy">Our Privacy Policy</Link>© 2023 Helplink.
            All rights reserved
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
