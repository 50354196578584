import { useEffect, useRef, useState } from "react";
import "./HelpLink.scss";
import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import ScrollWidget from "./components/ScrollWidget";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function HelpLink() {
  const homeRef = useRef<HTMLDivElement>(null);
  const whoWeAreRef = useRef<HTMLDivElement>(null);
  const partnersRef = useRef<HTMLDivElement>(null);
  const userExpRef = useRef<HTMLDivElement>(null);
  const [isWidgetVisible, setIsWidgetVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.scrollTo(0, 0);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        (window.scrollY / document.documentElement.scrollHeight) * 100;

      if (scrollPosition > 30) {
        setIsWidgetVisible(true);
      } else {
        setIsWidgetVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Header
          homeRef={homeRef}
          whoWeAreRef={whoWeAreRef}
          partnersRef={partnersRef}
          isMobile={isMobile}
        />
        <Routes>
          <Route
            index
            element={
              <Home
                homeRef={homeRef}
                whoWeAreRef={whoWeAreRef}
                partnersRef={partnersRef}
                userExpRef={userExpRef}
                isMobile={isMobile}
              />
            }
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/who-we-are"
            element={<Home
              homeRef={homeRef}
              whoWeAreRef={whoWeAreRef}
              partnersRef={partnersRef}
              userExpRef={userExpRef}
              isMobile={isMobile}
            />}
          />
          <Route
            path="/user-experience"
            element={<Home
              homeRef={homeRef}
              whoWeAreRef={whoWeAreRef}
              partnersRef={partnersRef}
              userExpRef={userExpRef}
              isMobile={isMobile}
            />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer
          homeRef={homeRef}
          whoWeAreRef={whoWeAreRef}
          partnersRef={partnersRef}
          isMobile={isMobile}
        />
        {isWidgetVisible && <ScrollWidget />}
      </BrowserRouter>
    </>
  );
}

export default HelpLink;
