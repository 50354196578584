import './../HelpLink.scss';
import homeBanner from './../assets/home-banner.png';
import infoBanner from './../assets/who-we-are-banner.png';
import infoBannerMobile from './../assets/who-we-are-banner-mobile.png';
import shopify from './../assets/partners/shopify.png';
import bigCommerce from './../assets/partners/big-commerce.png';
import wooCommerce from './../assets/partners/woo-commerce.png';
import twilio from './../assets/partners/twilio.png';
import telnyx from './../assets/partners/telnyx.png';
import sendlane from './../assets/partners/sendlane.png';
import { useEffect, useRef, useState } from 'react';
import thumbnail from './../assets/video-poster.png';
import telnyxVideo from './../assets/telnyx.mp4';

const Home = ({
  homeRef,
  whoWeAreRef,
  partnersRef,
  userExpRef,
  isMobile,
}: {
  homeRef: React.RefObject<HTMLDivElement>;
  whoWeAreRef: React.RefObject<HTMLDivElement>;
  partnersRef: React.RefObject<HTMLDivElement>;
  userExpRef: React.RefObject<HTMLDivElement>;
  isMobile: boolean;
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const scrollToWhoWeAre = () => {
    if (whoWeAreRef.current) {
      let offset;
      if (window.location.pathname.includes('who-we-are'))
        offset = isMobile ? 30 : -50;
      else offset = isMobile ? 0 : -100;
      const targetPosition =
        whoWeAreRef.current.getBoundingClientRect().top +
        window.scrollY -
        offset;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };

  const scrollToUserExp = () => {
    if (userExpRef.current) {
      const offset = 30;

      const targetPosition =
        userExpRef.current.getBoundingClientRect().top +
        window.scrollY -
        offset;
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const video = videoRef.current;

    // Handle when the video starts playing
    const handlePlay = () => setIsVideoPlaying(true);

    // Handle when the video pauses or ends
    const handlePause = () => setIsVideoPlaying(false);

    if (video) {
      video.addEventListener('play', handlePlay);
      video.addEventListener('pause', handlePause);
      video.addEventListener('ended', handlePause);
    }

    // Clean up the event listeners when the component unmounts
    return () => {
      if (video) {
        video.removeEventListener('play', handlePlay);
        video.removeEventListener('pause', handlePause);
        video.removeEventListener('ended', handlePause);
      }
    };
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes('who-we-are')) {
      setTimeout(() => {
        scrollToWhoWeAre();
      }, 0);
    }

    if (window.location.pathname.includes('user-experience')) {
      setTimeout(() => {
        scrollToUserExp();
      }, 500);
    }
  }, []);

  return (
    <>
      <div className="home-view" ref={homeRef}>
        <div className="overview">
          <strong className="title">Welcome!</strong>
          <span className="sub-title">Thanks for reaching Helplink.ai</span>
          <span className="description">
            A self-service option for customer support. If you received a text
            message, please click the link on your mobile device to continue
            your customer support request. If you did not receive a text message
            please contact the seller direct via phone or email.
          </span>
          {!isMobile && (
            <button className="learn-more-btn" onClick={scrollToWhoWeAre}>
              Learn More
            </button>
          )}
        </div>
        <div className="home-banner">
          <img src={homeBanner} alt="home-banner" />
        </div>
        {isMobile && (
          <button className="learn-more-btn" onClick={scrollToWhoWeAre}>
            Learn More
          </button>
        )}
      </div>

      {/* Who We ARe */}
      <div className="info-view" ref={whoWeAreRef}>
        <strong className="title">WHO WE ARE</strong>
        <span className="sub-title">
          Helplink.ai is used to help convert inbound calls to a digital,
          self-service option for consumers. Allowing access to 24/7 365
          support.
        </span>
        <img
          src={!isMobile ? infoBanner : infoBannerMobile}
          alt="info-banner"
        />
      </div>

      {/* User Experience */}
      <div className="user-customer-experience" ref={userExpRef}>
        <strong className="title">USER CUSTOMER EXPERIENCE</strong>
        <span className="sub-title">
          Leveraging SMS Technology to convert Phone to Digital.
        </span>
        <div className="video-container">
          {!isVideoPlaying && (
            <button
              className="play-icon"
              onClick={() => videoRef.current?.play()}
            >
              <svg
                viewBox="0 0 448 512"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                width="26px"
              >
                <path
                  d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>
          )}
          <video
            width="100%"
            controls={isVideoPlaying}
            poster={thumbnail}
            ref={videoRef}
            src={telnyxVideo}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/* Our Partners */}
      <div className="partners-view" ref={partnersRef}>
        <strong className="title">OUR PARTNERS</strong>
        {!isMobile ? (
          <>
            <div className="icons-grid">
              <img src={shopify} alt="partner-logo" />
              <img src={bigCommerce} alt="partner-logo" />
              <img src={wooCommerce} alt="partner-logo" />
            </div>
            <div className="icons-grid">
              <img src={twilio} alt="partner-logo" />
              <img src={telnyx} alt="partner-logo" height={52} />
              <img src={sendlane} alt="partner-logo" height={54} />
            </div>
          </>
        ) : (
          <div className="icons-grid">
            <img src={shopify} alt="partner-logo" />
            <img src={bigCommerce} alt="partner-logo" />
            <img src={wooCommerce} alt="partner-logo" />
            <img src={twilio} alt="partner-logo" />
            <img src={telnyx} alt="partner-logo" />
            <img src={sendlane} alt="partner-logo" />
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
